// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-hcc-index-js": () => import("./../../../src/pages/hcc/index.js" /* webpackChunkName: "component---src-pages-hcc-index-js" */),
  "component---src-pages-hcc-organon-access-program-js": () => import("./../../../src/pages/hcc/organon-access-program.js" /* webpackChunkName: "component---src-pages-hcc-organon-access-program-js" */),
  "component---src-pages-hcp-index-js": () => import("./../../../src/pages/hcp/index.js" /* webpackChunkName: "component---src-pages-hcp-index-js" */),
  "component---src-pages-hcp-organon-access-program-js": () => import("./../../../src/pages/hcp/organon-access-program.js" /* webpackChunkName: "component---src-pages-hcp-organon-access-program-js" */)
}

